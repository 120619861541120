import { useImage } from '#imports'

const nuxtImage = useImage() // composable given by @nuxt/image

export function getOptimizedImage(src: string) {
  // return getIpxOptimizedImage(src)
  return getTwicpicsOptimizedImage(src)
}

// use @nuxt/image ipx to generate optimized thumbnail from image
function getIpxOptimizedImage(src: string) {
  if (!src || src === '') return ''
  const { getImageUrl } = useAzBlobUrl()
  return nuxtImage(getImageUrl(src), undefined, { provider: 'ipx', preset: 'small' })
}

// use @nuxt/image ipx to generate optimized thumbnail from image
function getTwicpicsOptimizedImage(src: string) {
  if (!src || src === '') return ''
  // console.log('test: ' + JSON.stringify(nuxtImage.options))
  const img = nuxtImage(src, undefined, {
    provider: 'twicpics',
    preset: 'small',
  })
  return `https://divinesamples.twic.pics/images${img}`
}

// return youtube video image with medium quality
export function getYtVideoImageMQ(url: string) {
  return url.replace('default.jpg', 'mqdefault.jpg')
}

// return youtube channel image with medium quality
export function getYtChannelImageMQ(url: string) {
  return url.replace('s88', 's240')
}
